<template>
    <div class="main">
        <div class="kefan">
            <div class="top">
                <div class="tabs" v-bind:class="{ 'choosed': item.selected }" v-for="item in tabs" :key="item.value"
                    @click="changetab(item.value)">
                    {{ item.name }}
                </div>
            </div>
            <!-- 下拉 -->
            <div class="top">
                <el-select v-model="value" placeholder="请选择" @change="changevalue">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">

                    </el-option>
                </el-select>

                <el-date-picker v-model="date" type="date" @change="changedate" value-format="yyyy-MM-dd"
                    placeholder="选择日期">

                </el-date-picker>
            </div>

            <!-- 表格 -->
            <div class="table">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column :prop="item.prop" :label="item.label" v-for="item in label" :key="item.prop"></el-table-column>
                </el-table>
            </div>

            <!-- 页码 -->
            <div class="page">
                <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="page"
                layout="prev, pager, next"
                :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import config from '../../config'
let api = config.API
export default {
    data() {
        return {
            tabs:
                [
                    { name: '会议室预约信息', value: 0, selected: true },
                    { name: '车备箱预约信息', value: 1, selected: false },
                    { name: '花架预约信息', value: 2, selected: false },
                ],
            tab: 0,
            loading: false,
            options: [],
            value: 0,
            date: "",
            page: 1,
            pagesize: 10,
            total: 0,
            tableData: [],
            label: []
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.init().then(() => {
            this.loadoptions();
            this.loaddata();
        });
    },
    methods: {
        // 切换标签栏
        changetab(e) {
            this.tabs.forEach(item => {
                item.selected = false;
                if (item.value == e) {
                    item.selected = true;
                }
            });
            this.tab = e;
            this.value = 0;
            this.page = 1;
            this.loadoptions();
            this.loaddata();
        },
        // 选择下拉框
        changevalue(e) {
            this.value = e;
            this.page = 1;
            this.loaddata();
        },
        // 更换日期
        changedate(e) {
            this.date = e;
            this.page = 1;
            console.log(e);
            this.loaddata();
        },
        // 页码切换
        handleCurrentChange(val) {
        this.page = val;
        this.loaddata()
    },

        // 初始化数据
        init() {
            return new Promise((resolve, reject) => {
                try {
                    // 初始化日期对象，默认为今天的0点
                    var date = new Date();
                    date.setHours(0, 0, 0, 0);
                    // 将日期格式化成yyyy-MM-dd
                    this.date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                    resolve(); // 成功时调用 resolve
                } catch (error) {
                    reject(error); // 失败时调用 reject
                }
            });
        },
        // 加载options
        loadoptions() {
            this.$post(api.list, { do: "tablist", tab: this.tab }).then(res => {
                if (res.data.code == 200) {
                    this.options = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            })
                .catch(err => {
                    this.$message.error(err);
                }
                );
        },

        // 加载表格数据
        loaddata() {
            let load = this.date.loading
            if (load) {
                return;
            }
            this.loading = true;
            let loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$post(api.list, { do: "reserve", tab: this.tab, value: this.value, date: this.date, page: this.page, pagesize: this.pagesize }).then(res => {
                if (res.data.code == 200) {
                    this.tableData = res.data.data.data;
                    this.total = res.data.data.total;
                    this.label = res.data.data.label;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).then(() => {
                this.loading = false;
                loading.close();
            })
        },
    }
};
</script>
<style scoped>
.main {
    width: 100%;
}

.top {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.page{
    width: 90%;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.tabs {
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
}

.tabs:hover {
    background-color: #52C290;
    color: #fff;
}

.choosed {
    background-color: #52C290;
    color: #fff;
}
</style>